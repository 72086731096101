@import "../../../styles/resources.scss";

.back {
  text-decoration: none;
  color: #152935;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.leftArrow {
  position: relative;
  right: 4px;
  height: initial;
  width: 13px;
}
