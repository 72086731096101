@import "styles/resources.scss";

.syncAmsAdsBtn {
  font-size: 9px;
  position: absolute;
  top: 5px;
  left: -14px;
  padding: 0px 3px;
  background-color: $light-blue;
}

.warningIcon {
  margin-right: 5px;
}

.hasUpdatesBtn {
  background-color: $light-red;
  color: $red;
  border: 1px solid $red !important;
}

.hideIcon {
  display: none;
}

.noUpdatesBtn {
  color: #177de3 !important;
  border: none !important;
}

.readOnlyBtn {
  left: 15px;
}
