@import "styles/resources";

.comLangBtn {
  font-size: 9px;
  padding: 0px 3px;
  background-color: $light-blue;
}

.noUpdatesBtn {
  color: #177de3 !important;
  border: none !important;
}

.hasUpdatesBtn {
  background-color: $light-red;
  color: $red;
  border: 1px solid $red !important;
}

.warningIcon {
  margin-right: 5px;
}

.dropdown {
  position: absolute !important;
  top: 5px;
  left: -14px;
}

.readOnlyDropdown {
  left: 0px !important;
}

.dropdownContent {
  padding: 15px;

  .unlinkTextDropdown {
    text-wrap: nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .unlinkBtnDropdown {
    display: flex;
    justify-content: center;
  }
}

.sortMode {
  left: 0px !important;
}
