@import "../../../styles/resources.scss";

.modalContainer {
  width: 1026px !important;
}

.modalBody {
  height: 448px;
  padding: 0 !important;
  overflow-y: scroll;
}

.modalFooter {
  box-shadow: 0px -1px 8px 0px rgba(0, 0, 0, 0.15);
}

.unlinkButton {
  margin-right: auto;
}
