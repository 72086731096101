@import "styles/resources";

.aapDescription {
  height: 120px;
  width: 350px;
}

.stickyNav td {
  position: -webkit-sticky;
  position: sticky;
  background: $white;
  top: 0;
  z-index: 2;
  font-weight: 500;
}

.noResize {
  resize: none;
}

.aapTable {
  tbody.appBody tr td {
    padding-right: 10px;
    font-size: 12px;
  }
}

.checkboxAll label {
  margin-bottom: 16px;
}

.smallCells {
  max-width: 63px !important;
}

.tableTextArea {
  border: 1px solid $table-input-border;
  border-radius: 3px;
  background-color: transparent;
  color: $primary-color;
  width: 135px;
  resize: none;
  padding: 8px;
  font-size: 12px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }
}
