@import "styles/resources";

.sideMenuIcon {
  > button {
    padding: 0;

    > span {
      display: contents;

      svg {
        width: auto;
        height: 24px;
        fill: currentColor;
      }
    }
  }
}

.helpMenuIcon {
  position: absolute !important;
  bottom: 8.4em;
  left: 0;
  right: 0;
}
