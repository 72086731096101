@import "styles/resources";

.commonLanguageLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 14px;
  border: 0;
  border-radius: 2px;
  background-color: $lighter-gray;
  color: $slate;
}

.disabled::after {
  border-color: $gray !important;
}

.stickyNav td {
  position: -webkit-sticky;
  position: sticky;
  background: $white;
  top: 0;
  z-index: 2;
  font-weight: 500;
}

.table {
  thead tr td {
    padding-right: 10px;

    &:first-child {
      padding-left: 10px;
    }
  }

  tbody tr td {
    padding-top: 5px;
    padding-right: 10px;
    font-size: 12px;

    &:first-child {
      padding-left: 10px;
    }
  }
}

.img {
  max-height: 50px;
}
