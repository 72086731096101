.label {
  position: relative;
  top: 3px;
  font-size: 12px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  position: absolute;
  width: 100%;
}

.checkbox {
  display: inline-flex;
  flex: 1 1 50%;
}
