@import "../../../styles/resources";

.productTypeColumn {
  text-align: center;
  min-width: 150px;
}

.accInputEmpty {
  border: none;
}

textarea {
  resize: none;
  height: 100%;
  border: 1px solid #cdd1d4;
}

.noTable {
  background-color: #f4f7fb;
}

.requiredField {
  border: 1px solid red;
}

.accTable {
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }
}

.modelApplicabilityGradeHeader {
  width: 169px !important;
  font-size: 12px;
  color: $blue;
  background-color: $lighter-slate;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  font-weight: 700;
}

.modelApplicabilityHeader {
  min-width: 167px;
  padding-left: 1.5px;
  padding-top: 9px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}

tr.bodyRow {
  height: 183px;
  &:nth-child(even) {
    background-color: $white;
  }
  &:nth-child(odd) {
    background-color: $lighter-slate;
  }
}

.accButtonAlignFlagsCell {
  padding-left: 10px;
}

.accMsrp {
  width: 110px;
}

.separator {
  padding-bottom: 10px;
}

.applyAllBtn {
  color: #767676;
  background-color: #f2f2f2;
  border: 1px solid #767676;
  height: 15px;
  font-size: 12px;
  outline: none;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  padding: 0 5px;
  cursor: pointer;
}

.wrapper {
  position: absolute;
  bottom: 10px;
  width: 100%;
  align-items: center;
}

.installPointCell {
  min-width: 110px;
}

.installPointLabels {
  position: relative;
  top: 3px;
}

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

.nonGen {
  margin-top: 10px;
}

.lexusDealerCostColumn {
  > span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.currencyInput {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: left;
}

.currencyInput input {
  padding-left: 12px;
}

.currencyInput:before {
  position: absolute;
  content: "$";
  left: 5px;
}

.hasSyncChanges button:first-child {
  border: 1px solid $red;
  background-color: $light-red;
}

.thumbsUpCta {
  padding-bottom: 14px;
}

// table header widths

.titleHeaderWidth {
  min-width: 150px;
  padding: 0 !important;
}

.sortTitleHeaderWidth {
  min-width: 150px;
}

.sortCopyHeaderWidth {
  min-width: 210px;
}
.copyHeaderWidth {
  min-width: 180px;
}

.filterContainer {
  width: 344.5px;

  .secondaryHeader {
    padding: 5px 0 0 32px;
  }
}

.sortSelectClassName {
  li {
    width: auto;
  }
}
