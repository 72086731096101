@import "styles/resources";

.imageUploadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed $light-gray;
  background: $lighter-gray;
  width: 100%;
  height: 250px;

  &.withImage {
    border: none;
    background: none;
    height: auto;
  }
}

.imageUploadPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.imageUploadFooter {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.imageUploadPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $slate;
  font-size: 14px;
}
