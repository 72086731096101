@import "../../../styles/resources";

.productTypeColumn {
  text-align: center;
  width: 150px;
}

.langBoxes {
  display: flex;
}

.threeTable {
  display: inline-flex;
  border: 1px solid #edf0f5;
}

.noDataCells {
  padding: 16px 8px;
  height: 150px;
  width: 150px;
}

[data-collapsed~="true"] .stickySection {
  left: 96px;
}
.stickyblocky {
  position: -webkit-sticky;
  position: sticky;
  left: 216px;
  display: inline-block;
}
.inlineblock {
  display: inline-block;
}
.stickySection {
  top: inherit;
  z-index: 5;
  left: 216px;
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  & tr {
    border-right: 0px solid;
  }
  & th {
    top: -1px;
    z-index: 6;
    position: -webkit-sticky;
    position: sticky;
    background: white;
  }
}
.removeBorderRight {
  border-right-style: none;
  border-left: 1px solid #edf0f5;
}
.removeBorderLeft {
  border-left-style: none;
  z-index: 1;
}

tr th.stickySection {
  top: 0;
  left: 0;
  z-index: 6;
  position: -webkit-sticky;
  position: sticky;
  border-bottom: 1px solid #cdd1d4;
}

.accInputEmpty {
  border: none;
}

textarea {
  resize: none;
  height: 100%;
  border: 1px solid #cdd1d4;
}

.noTable {
  background-color: #f4f7fb;
}

.requiredField {
  border: 1px solid red;
}

tr.headerRow {
  height: 90px !important;
}

tr.bodyRow {
  height: 183px;
  &:nth-child(even) {
    background-color: $white;
  }
  &:nth-child(odd) {
    background-color: $lighter-slate;
  }
}

.accButtonAlignFlagsCell {
  padding-left: 10px;
}

.accMsrp {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  width: 110px;
  font-family: "Roboto", sans-serif;

  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.headerLabel {
  position: relative;
}

.nameHeader {
  padding: 2px;
  text-align: left;
  font-family: $font-family-roboto;
  @include fontSize(14px);
  color: $navy;
  font-weight: 500;

  &.clickable {
    cursor: pointer;
  }
}

.descCheckbox {
  display: inline-flex;
  position: relative;
  top: 15px;
  width: 48%;
  font-size: 12px;
}

.checkboxLabel {
  position: relative;
  top: 3px;
}

div.checkboxCell {
  min-width: 104px;
  position: relative;
  label {
    padding-left: 0px;
    outline: none;
    cursor: pointer;

    &::before {
      content: "--";
      position: initial;
      background-color: inherit;
      border: none;
    }

    &::after {
      border-color: transparent;
    }
  }

  input:checked + label {
    &::before {
      content: "";
      position: initial;
      background-color: inherit;
      border: none;
    }

    &::after {
      content: "";
      left: 6px;
      top: 2px;
      border-color: $blue;
    }
  }
}

.applyAllBtn {
  color: #767676;
  background-color: #f2f2f2;
  border: 1px solid #767676;
  height: 15px;
  font-size: 12px;
  outline: none;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  padding: 0 5px;
  cursor: pointer;
}

.wrapper {
  position: absolute;
  bottom: 10px;
  width: 100%;
  align-items: center;
}

.relative {
  position: relative;
}

.modelApplicabilityGradeHeader {
  width: 169px !important;
  font-size: 12px;
  color: $blue;
  background-color: $lighter-slate;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  font-weight: 700;
}

.modelApplicabilityHeader {
  min-width: 167px;
  padding-left: 1.5px;
  padding-top: 9px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
}

.appModal {
  width: 1250px;
}

.nationalBtn {
  font-size: 9px;
  position: absolute;
  top: 5px;
  left: -14px;
  padding: 0px 3px;
  background-color: $light-blue;
}

.noUpdatesBtn {
  color: #177de3 !important;
  border: none !important;
}

.hasUpdatesBtn {
  background-color: $light-red;
  color: $red;
  border: 1px solid $red !important;
}

.warningIcon {
  margin-right: 5px;
}

.esTextArea {
  height: 115px;
  font-size: 12px;
}

.readOnlyBtn {
  left: 0px !important;
}

.positionAbsolute {
  position: absolute;
}

// table header widths

.abbTCWidth {
  min-width: 140px;
}

.sortOrderWidth {
  min-width: 140px;
}

.nameTCWidth {
  min-width: 320px;
}

.supplierTCWidth {
  min-width: 155px;
}

.collapseToggleWidth {
  min-width: 160px;
}

.sortOrderWidth {
  min-width: 47px;
}

.titleCell {
  z-index: 6;
}

.sortSelectClassName {
  li {
    width: auto;
  }
}
