@import "styles/resources";

.statusIndicator {
  display: inline-block;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 2px;
  border: 1px solid $slate;
  background: $white;
  color: $slate;
  font-weight: 500;
  margin-bottom: 10px;
}
