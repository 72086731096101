@import "styles/resources";

.syncAllChangesTable {
  thead tr td {
    z-index: 2;
    font-weight: 500;
    padding-left: 16px !important;
  }

  tbody tr td {
    font-size: 14px !important;
    padding-left: 16px !important;
  }
}

.checkboxCell {
  width: 64px !important;
  max-width: 64px !important;
}

.checkboxCell > span {
  display: flex;
  flex-direction: row;

  .checkboxAll {
    display: flex;
  }
}

.checkboxLabel {
  margin: 0;
  font-weight: 500;
}

.checkBox {
  padding-right: 25px;
}

.goLiveDate {
  text-transform: uppercase;
}

.syncAllChangesModalBodyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;

  .searchInput {
    width: 300px;
    max-width: 300px;
  }
}
