@import "styles/resources";

.menuButton {
  height: 30px;
  padding: 3px 12px;
  font-size: 12px;
  border: 1px solid $blue;
  color: $blue;
  background-color: $white;

  &:active,
  &.toggled {
    background-color: $light-blue;
  }
}
