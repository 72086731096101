.dropdownList {
  min-width: 78px !important;

  button {
    width: -moz-available !important;
    width: -webkit-fill-available !important;
  }

  li {
    justify-content: center !important;
    width: -moz-available !important;
    width: -webkit-fill-available !important;
  }
}

.adminOutOfSync {
  td[id="VehicleTableRow-adminOutOfSyncColumn"] {
    padding: 0 16px;
    min-width: 209px;
    max-width: 209px;
  }

  td[id="VehicleTableEmptyRow-adminOutOfSyncColumn"] {
    padding: 0 16px;
    min-width: 209px;
    max-width: 209px;
  }
}

.outOfSync {
  td:nth-last-child(2) {
    padding: 0 16px;
    min-width: 310px;
    max-width: 310px;
  }
}
