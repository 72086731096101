@import "../../../../../styles/resources.scss";

.checkboxCell {
  min-width: 104px;

  label {
    padding-left: 0px;
    outline: none;
    cursor: pointer;

    &::before {
      content: "--";
      position: initial;
      background-color: inherit;
      border: none;
    }

    &::after {
      border-color: transparent;
    }
  }

  input:checked + label {
    &::before {
      content: "";
      position: initial;
      background-color: inherit;
      border: none;
    }

    &::after {
      content: "";
      left: 6px;
      top: 2px;
      border-color: $blue;
    }
  }
}
.relative {
  position: relative;
}
