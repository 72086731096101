@import "styles/resources";

.stickyNav td {
  position: -webkit-sticky;
  position: sticky;
  background: $white;
  top: 0;
  z-index: 2;
  font-weight: 500;
}

.aclTable {
  thead.aclHead tr td {
    padding-right: 10px;
  }

  thead.aclHead tr td:first-child {
    padding-left: 10px;
  }

  tbody.aclBody tr td {
    padding-top: 5px;
    padding-right: 10px;
    font-size: 12px;
  }

  tbody.aclBody tr td:first-child {
    padding-left: 10px;
  }
}

.flageCellImage {
  max-height: 50px;
}

.checkboxDisabled::after {
  border-color: $gray !important;
}
