@import "styles/resources.scss";

.dropdown {
  .dropdownButton {
    width: 140px;
    min-height: 52px;
    border: 0;
    padding: calc(0.875em - 1px) 0.75em;
    padding-right: 32px;
    height: auto;
    color: $secondary-color;
    max-width: 150px;
    text-align: left;

    @include fontSize(12px);
    font-family: $font-family-roboto;

    &:disabled {
      border: none;
      background-image: none;
      cursor: default;
    }

    &.error {
      border: solid 1px $red !important;
      background-color: $light-red;
    }
  }
}
