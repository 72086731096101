@import "../../../../../styles/resources.scss";

.goLiveDate {
  color: $blue;
  cursor: pointer;
}

.publishedGoLiveDate {
  color: $blue;
  cursor: auto;
}

.publishedPastGoLiveDate {
  color: $slate;
  cursor: auto;
}

.goLiveDateModal {
  display: flex !important;
  justify-content: center;
}

.goLiveDateBtn {
  border: 0;
  text-wrap: nowrap;
  background: none;
  text-decoration-line: underline;
}

.goLiveDateCol {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.goLiveDateBtnAll {
  font-weight: bold;
  text-decoration-line: none;
  color: black;
  display: flex;
}

.goLiveTimeText {
  font-size: 10px;
  margin-top: 5px;
}

.icon {
  display: inline-block;
  margin-left: 8px;
}

.iconChevronDown {
  color: $blue;
}

.confirmEditModal {
  text-wrap: nowrap;

  b {
    margin: 0 0 0 5px;
  }
}

.modelFooter {
  border-top: 1px solid #cdd1d4;
  justify-content: flex-start;
  flex-direction: column;
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
}

.goLiveDateSyncAdmin {
  display: flex;
  align-items: center;
  position: relative;
}

.iconSyncAdmin {
  color: $red;
  position: absolute;
  left: -20px;
}
