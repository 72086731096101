@import "styles/resources.scss";

.cell {
  min-width: 132px;
  width: 132px;
  height: 188px;
  padding: 12px;
  box-sizing: border-box;

  &.actionButtons {
    min-width: 60px;
    width: 60px;
  }

  &.textArea {
    min-width: 140px;
    width: 140px;

    &.withLabel {
      height: calc(100% - 24px);
    }

    &.wide {
      min-width: 232px;
      width: 232px;
    }
  }
}

[data-collapsed~="true"] .sticky {
  left: 96px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 5;
  left: 216px;
  background-color: white;
}

.laborTime {
  margin-top: 10px;
}

.syncAmsAdsCell {
  position: relative;
}
