@import "../../../styles/resources.scss";

#error {
  border: 1px solid $red !important;
}

.msrpSection {
  display: flex;
  flex-direction: column;
  width: 230px;
}

.inputContainer {
  display: inline;
  position: relative;
}

.dropdownInputContainer {
  display: flex;
  flex-direction: row;
}

.msrpLabel {
  color: $green;
  cursor: pointer;
}

.deleteBtn {
  padding-top: 6px;
}

.header {
  font-weight: bold;
  margin-bottom: 5px;
  color: $primary-color;
  font-size: 15px;
  margin-top: 10px;
}

.currencyInput {
  position: relative;
}

.currencyInput input {
  padding-right: 18px;
  width: 184px;
  margin-top: 10px;
  padding-left: 12px;
}

.currencyInput:after {
  position: absolute;
  content: "$";
  width: 0;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 50px;
}

.gradeCurrencyInput input {
  padding-right: 18px;
  width: 161px;
  padding-left: 12px;
}

.gradeCurrencyInput:after {
  position: relative;
  content: "$";
  width: 0;
  top: -24px;
  bottom: 0;
  right: 0;
  left: 10px;
}

.dropdown {
  width: 100%;
  position: static;
  border: 1px solid $light-gray;
  li {
    list-style: none;
    padding: 10px 8px;
  }
  li:hover {
    background-color: $light-blue;
  }
}

.dropdownButton {
  width: 100%;
  background-color: $white;
  border: 1px solid $light-gray;
  border-radius: 3px;
  min-width: 40px;
  min-height: 27px;
}

.dropdownContent {
  z-index: 99999;
}

.label {
  font-size: 12px;
  margin-right: 25px;
  font-weight: bold;
}

.input {
  margin-left: 5px;
  margin-bottom: 5px;
  width: 160px;
}

.msrpIcon {
  size: 12px;
}

.tableDropdown {
  width: 100%;
  min-height: 27px;
  border: 1px solid $table-input-border;
  padding-right: 32px;
  height: auto;
  color: $secondary-color;
  max-width: 150px;
  text-align: left;

  &:disabled {
    border: none;
    background-image: none;
    cursor: default;
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.errItem {
  @extend #error;
}

.dropdownMaxWidth {
  max-width: 65px;
}

.hasSyncChanges {
  border: 1px solid $red;
  background-color: $light-red;
}
