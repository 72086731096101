@import "../../../styles/resources.scss";

.searchInput {
  width: 100%;
  margin-bottom: 24px;
}

.modalBody {
  height: 448px;
}

.modalFooter {
  border-top: 1px solid $light-gray;
}
