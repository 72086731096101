@import 'styles/resources';

.seriesManagerContainer {
  text-align: center;
}

.heading {
  height: 64px;
  text-align: center;
  margin-top: 181px;
}

.categoriesContainer {
  display: inline-flex;
}

.tilesWrapper {
  max-width: 890px;
}

.categories {
  height: 35px;
  width: 200px;
  font-weight: 600;
  line-height: 23px;
  border-left: 1px solid $light-slate;
  cursor: pointer;
  border-top: none;
  border-bottom: none;
  border-right: none;
  font-size: 16px;
  outline: none;
}

.long {
  width: 230px;
}

.trucks {
  border-right: 1px solid $light-slate;
}

.selected {
  border-bottom: 2px solid $tertiary-color;
  border-radius: 5%;
}

.tilesContainer {
  padding-top: 33px;
  display: flex;
  justify-content: center;
}

.tile {
  box-sizing: border-box;
  height: 152px;
  width: 200px;
  border: 2px solid $light-slate;
  border-radius: 5px;
  background-color: $white;
  margin-left: 16px;
  display: inline-flex;
  margin-top: 10px;
}

.tileSelected {
  border: 2px solid $tertiary-color;
}

.carJellyImage {
  height: 50px;
  width: 130px;
  position: absolute;
  margin-top: 55px;
  display: flex;
  margin-left: 30px;
}

.checkbox {
  label {
    display: flex;
    margin: 0px 25px 0px 0px;
    outline: none;
    padding-left: 10px;
    padding-top: 8px;
    white-space: pre-wrap;
    line-height: 97%;

    &::before {
      top: 5px;
      left: 165px;
      width: 24px;
      height: 24px;
      border-radius: 14px;
    }
    &::after {
      left: 175px;
      top: 12px;
    }
  }
  input:checked + label::after {
    border-color: $white;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
  }

  input:checked + label::before {
    background-color: $tertiary-color;
    border: 1px solid $tertiary-color;
  }
}

.selections {
  padding-top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectionLabel {
  height: 23px;	
  width: 122px;	
  letter-spacing: 0.03px;	
  line-height: 23px;
}

.list {
  max-width: 550px;
}

.selectionCta {
  margin-top: 5px;
  overflow:hidden;
  white-space: nowrap;
}

.selectionCtaText { 
  width: 70px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.saveVehicles {
  display: flex;
  justify-content: center;
  margin-top: 52px;
  button {
    width: 162px;
  }
}

.seperator {
  box-sizing: border-box;	
  height: 1px;	
  // width: 1134px;	
  border-bottom: 1px solid $light-gray;
  margin-top: 31.5px;
}

.addVehicle {
  display: flex;
  justify-content: center;
  margin-top: 31.5px;
  margin-bottom: 245px;
  button {
    width: 162px;
  }
}
