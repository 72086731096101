@import "../../../styles/resources.scss";

.toast {
  :global {
    .Toastify {
      &__toast {
        padding: 24px;
        box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);

        &--error {
          background: $light-red;
          border: 1px solid $red;
          color: $red;
        }

        &--success {
          background: $light-green;
          color: $green;
        }
      }

      &__toast-container {
        margin-left: 0; // this centers the toast. idk how
        margin-top: -8px;
      }

      &__toast-body {
        font-size: 14px;
        padding-right: 24px;
      }
    }
  }
}

.toastClose {
  position: relative;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid #aaa;
  border-radius: 10px;
  cursor: pointer;

  &::after {
    content: "x";
    position: absolute;
    top: -1.5px;
    left: 3.5px;
    color: #aaa;
  }
}
