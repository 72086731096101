@import "styles/resources";

.list {
  display: none;
}

.container {
  width: 378px;
  padding: 16px;
  font-family: $font-family-roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.217px;
  border-radius: 3px;
  border: 1px solid $light-gray;
  background: $white;
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.15);

  .subContainer {
    padding-left: 24px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
}

.itemContaimer {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 12px;

  .label {
    margin: 0 8px;
  }

  .dropdownBtn {
    display: flex;
    height: 32px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
    border: 1px solid $light-gray;
  }
}

.dragIcon {
  color: $slate;
  font-size: 18px;
}
