@import "../../../../../../styles/resources.scss";

.darkText {
  font-size: 12px;
  font-weight: 400;
  color: $navy;
}

.lightText {
  font-size: 12px;
  font-weight: 400;
  color: $slate;
}

.boldText {
  font-weight: 500;
}

.timePicker {
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  > div:first-child {
    margin-right: 7px;
  }

  .time {
    height: 100%;

    .cta {
      border: 1px solid #cdd1d4;
      border-radius: 3px;
      height: auto;
      min-height: 2.25em;
      width: 80px;
      text-align: left;
      cursor: pointer;
      &:hover {
        background-color: $light-blue;
      }
      @extend .darkText;
    }

    > div > div {
      width: 100%;
      max-height: 290px;
      overflow: auto;

      ul {
        li {
          width: auto;
        }
      }
    }
  }

  .dropdownList {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      width: 240px;
      padding: 16px 16px;
      cursor: pointer;

      &:hover {
        background-color: $light-blue;
      }
    }
  }

  .adminDate {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }
}
