@import "../../../styles/resources.scss";

.input {
  @include formControl();
  box-sizing: border-box;
}

label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 8px;
  font-size: 12px;
}

.smallInput {
  width: 45px;
  text-align: center;
  height: 50px;
  margin: 10px;

}

.smallInputHeader {
  text-align: center;
  border-left: 1px solid $table-border;
  border-right: 1px solid $table-border;
}