@import "styles/resources";

.btnStyles {
  background: none;
  border: none !important;
  display: flex;
  width: 100%;
  height: 100%;
}

.btnList {
  background: none;
  border: none;
}

.browseBtn {
  width: 73px;
}

.EditInput {
  width: 75%;
  margin-top: 5px;
  margin-right: 5px;
}

.ImageTable {
  border: 1px solid $light-gray;
  margin-top: 5px;
  min-height: 220px;
  max-height: 220px;
  overflow: scroll;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: flex;
    }
  }
}

.selected {
  background-color: $light-blue;
  color: $blue;
  font-weight: bold;
}

.tableHeader {
  font-weight: 500;
  font-size: medium;
  padding-top: 5px;
}

.modalImage {
  width: 20%;
  height: 50px;
}

.imageName {
  width: 65%;
  display: inherit;
  margin: auto;
}

.input {
  width: 425px;
  margin: 0px 25px;
}

.selectedImage {
  max-height: 250px;
  width: 100%;
  margin-top: 10px;
}

.editImageModalTable {
  margin-top: 10px;
}

.imageLibraryCheckbox {
  padding: 0px 5px 10px 0px;
}

.selectedPartName {
  padding-bottom: 15px;
  font-weight: 300;
}

.footerSplit {
  display: flex;
  justify-content: space-between;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formField {
  display: flex;
  flex-direction: column;
}

.imageUploadContainer {
  margin-bottom: 21px;
}

.inputPartNumber {
  @include formControl();
}

.subTextWarning {
  color: $buttercup;
  font-weight: 500;
  font-size: 12px;
  margin-top: 12px;

  span {
    margin-left: 5px;
  }
}
