@import "../../../../styles/resources.scss";

.container {
  display: flex;
  border: 1px solid $light-gray;
  border-radius: 3px;
  padding: 12px;
  gap: 12px;
  align-items: center;

  .cardCheckbox {
    > label {
      display: inline;
    }
  }

  .cardImage {
    width: 60px;
    height: 32px;
  }

  .textBlock {
    display: flex;
    flex-direction: column;
    font-family: $font-family-roboto;

    .textTitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $black;
    }

    .textSubTitle {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: $black;
    }

    .description {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: $slate;
    }
  }
}

.selected {
  border: 1px solid $blue;
  background: $light-blue;
}

.selectedCheckbox {
  > label::before {
    border: 1px solid $blue;
    background: $light-blue;
  }
}
