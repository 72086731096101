@import 'styles/resources';

.filterTab {
  padding: 32px;
}

.filterTabBody {
  min-width: 700px;
  min-height: 150px;
  padding: 32px;
}

.filterTabRow {
  display: flex;
  border-bottom:1px solid $light-gray;
}

.filterTabButton {
  background: transparent;
  color: $primary-color;
  border: none;
  font-size: 16px;
  margin-right: 32px;
  padding-bottom: 16px;
  outline: none;
  cursor: pointer;

  &.active {
    color: $tertiary-color;
    border-bottom: 1px solid $tertiary-color;
    font-weight: bold;
  }
}

.filterTabFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.filterTabColumns {
  display: flex;
}

.filterTabColumn {
  + .filterTabColumn {
    margin-left: 32px;
  }
}

.filterTabColumnList {
  list-style: none;
  padding: 0;

  li {
    padding-left: 32px;
    margin-bottom: 16px;
  }
}

.filterTabSection {
  display: none;
  &.active { display: block; }
}
