@import "../../../../../../styles/resources.scss";

.root {
  width: 90%;
}

.tableDropdown {
  width: 140px;
  min-height: 52px;
  border: 0;
  padding: calc(0.875em - 1px) 0.75em;
  padding-right: 32px;
  height: auto;
  color: $secondary-color;
  max-width: 150px;
  text-align: left;

  @include fontSize(12px);
  font-family: $font-family-roboto;

  &:disabled {
    border: none;
    background-image: none;
    cursor: default;
  }
}

.hasSyncChanges {
  border: 1px solid $red;
  background-color: $light-red;
}
