@import "../../../../../../styles/resources.scss";

.msrpLabel {
  color: $green;
  cursor: pointer;
}

.msrpSection {
  width: 250px;
}
